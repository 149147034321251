export const CONFIG = {
    AGENT_LOCAL_HTTP: 'https://localhost:3001/',
    BUYER_LOCAL_HTTP: 'https://localhost:3000/',

    WEBSITE_HTTP: 'https://www.esignboard.com.au/',
    AGENT_HTTP: 'https://agent.esignboard.au',
    BUYER_HTTP: 'https://buyer.esignboard.au',

    API_AGENT_LOCAL: 'https://localhost:7075/',
    API_AGENT_DEV: 'https://esignboard-agent-api-dev.azurewebsites.net/',
    API_AGENT_UAT: 'https://es-uat-agent-api.azurewebsites.net/',
    API_AGENT_PROD: '',

    API_CLIENT_LOCAL: 'https://localhost:7000/',
    API_CLIENT_DEV: 'https://esignboard-api-dev.azurewebsites.net/',    
    API_CLIENT_UAT : 'https://es-uat-buyer-api.azurewebsites.net/',
    API_CLIENT_PROD: '',

    API_DOMAIN: 'https://api.domain.com.au/',
    API_DOMAIN_KEY1: 'key_a36a103b00413225fca9f7f8d693e2ea',
    API_DOMAIN_KEY2: 'key_b2ab025f00029c86e140b34c1da2b58c',
    API_DOMAIN_KEY3: 'key_683fd0d040229353f79a1d5f7e079fa9',

    API_QRCODE_BASE: 'https://api.qrserver.com/v1/create-qr-code/',

    GA_UAT_CLIENT_MEASUREMENT_ID: 'G-DW4FK67XER',
    GA_PROD_CLIENT_MEASUREMENT_ID: '',

    // Google Dev Account: https://console.cloud.google.com/apis/credentials?project=there-project
    // OAuth 2.0 Client IDs
    REACT_APP_GG_APP_ID: '880869037751-3ub8fp5klpblaq92ctpq7usjlnap155l.apps.googleusercontent.com',
    // Facebook Dev Account: https://developers.facebook.com/apps/760064845074423/fb-login/settings/
    // Facebook Login
    REACT_APP_FB_APP_ID: '760064845074423',
    REDIRECT_URI: 'https://localhost:3000',
}