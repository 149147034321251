import axios from 'axios';
import { CONFIG } from '../constants';
import { HubConnectionBuilder } from '@microsoft/signalr';

let agent_url = CONFIG.API_AGENT_UAT;
let client_url = CONFIG.API_CLIENT_UAT;
let domain_url = CONFIG.API_DOMAIN;

const getDomainApiKey = () => {
  let apiKey = [CONFIG.API_DOMAIN_KEY1, CONFIG.API_DOMAIN_KEY2, CONFIG.API_DOMAIN_KEY3];
  let retKey = apiKey[Math.round(Math.random() * 2)];
  return retKey;
};

const domainService = axios.create({
  baseURL: domain_url,
  timeout: 30000, // 请求超时时间
  headers: { 'X-Api-Key': getDomainApiKey() },
});

export const domainApiGet = (url) => {
  return new Promise((resolve, reject) => {
    domainService.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  });
};

const codeMessage = {
  200: 'The server successfully returned the requested data.',
  201: 'Data is created or modified successfully. ',
  202: 'The server has accepted the request but has not yet processed it. A request has been queued in the background (asynchronous task).',
  204: 'The server successfully processed the request, but did not return anything.',
  400: 'There was an error in the request, the server does not create or modify data. ',
  401: 'User does not have authorization(wrong token, username, password).',
  403: 'The server rejected the request.',
  404: 'The request is made for a record that does not exist, no operation is performed on the server.',
  405: 'The specified method in the request line cannot be used to request the corresponding resource.',
  406: 'unavailable requested format.',
  410: 'The requested resource is permanently deleted and will not be retrieved.',
  422: 'A validation error occurred while creating an object.',
  500: 'An error occurred on the server. Please check the server.',
  502: 'Bad gateway.',
  503: 'The service is unavailable due to temporarily overloaded or maintained.',
  504: 'Gateway Time-out.',
  505: 'The server does not support the HTTP protocol version used in the request.',
};

const service = axios.create({
  baseURL: client_url,
  timeout: 30000, // 请求超时时间
  headers: { 'esb-ss-id': localStorage.getItem('session') },
});

service.interceptors.request.use(
  (config) => {
    // 此处可在请求发起前对请求进行处理例如添加Token操作
    if (config.method === 'get') {
      // const t = new Date().getTime(); //为get请求添加时间戳，防止缓存问题
      // config.params.t = t;
    }
    return config;
  },
  (error) => {
    // 请求发生错误时可在此处处理
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // 此处可在统一处理请求回的数据
    return response;
  },
  (error) => {
    // 请求发生错误时可在此处处理
    if (error.request.responseURL.includes('signboard/count')) {
      return Promise.reject(error);
    } else {
      if (error.response.status === 401) {
        localStorage.removeItem('session');
        localStorage.removeItem('userId');
        localStorage.removeItem('user');
        localStorage.removeItem('signboard');
        localStorage.removeItem('signboard.images');
        setTimeout(() => (window.location.href = '/error/401'), 1000);
        return;
      } else if (error.response.status === 400) {
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
  }
);

export const request = (params) => {
  return new Promise((resolve, reject) => {
    service(params)
      .then((response) => {
        const errorText = codeMessage[response.status] || 'Unknown request error.';
        if (response.status === 200 || response.status === 204) {
          /* TODO:在实际业务中此处可根据与后端的约定状态码处理返回数据 */
          resolve(response);
          /* if (response.data.code === 0) {
            resolve(response.data.data);
          } else {
            reject(response.data.data);
          }*/
        } else {
          reject(errorText);
          const error = new Error(errorText);
          error.name = `${response.status}`;
          throw error;
        }
      })
      .catch((error) => {
        const errorText = '';
        if (error.response) errorText = codeMessage[error.response.status] || 'Unknown request error.';
        if (!error.message) error.message = errorText;
        reject(error);
      });
  });
};

export const originalRequest = (params) => {
  return service(params);
};

export const checkAccount = (email) => {
  let _url = '/account/check/email?email=' + email;
  return request({
    method: 'post',
    url: _url,
  });
};

// export const checkAccountAsync = async (email) => {
//     try {
//         const res = await axios({
//             method: 'post',
//             url: client_url + `account/check/email?email=${email}`
//         });
//         return await res;
//     }
//     catch (e) {
//         console.error(e);
//     }
// }

export const login = (email, password) => {
  return request({
    method: 'post',
    url: '/login',
    data: { email: email, password: password },
  });
};

export const logout = () => {
  let _url = '/logout';
  return request({
    method: 'post',
    url: _url,
  });
};

export const getScanSignboards = () => {
  let _url = '/signboard';
  return request({
    method: 'get',
    url: _url,
  });
};

export const getFavSignboards = () => {
  let _url = '/signboard/fav';
  return request({
    method: 'get',
    url: _url,
  });
};

export const getAllSignboards = () => {
  let _url = '/signboard/all?page=1&rows=6';
  return request({
    method: 'get',
    url: _url,
  });
};

export const postSignboardScanById = (esb_id) => {
  let _url = '/signboard/scan/' + esb_id;
  return request({
    method: 'post',
    url: _url,
  });
};
export const postSignboardScanByIdAndSession = (esb_id, session) => {
  const res = axios.post(
    client_url + 'signboard/scan/' + esb_id,
    {},
    {
      headers: {
        'esb-ss-id': session,
      },
    }
  );
  return res;
};

export const getSignboardById = (esb_id) => {
  let _url = '/signboard/' + esb_id;
  return request({
    method: 'get',
    url: _url,
  });
};

export const getSignboardFav = () => {
  let _url = '/signboard/fav';
  return request({
    method: 'get',
    url: _url,
  });
};

export const getChatById = (esb_id, sort) => {
  let _url = '/signboard/' + esb_id + '/chat?sortAsc=' + sort;
  return request({
    method: 'get',
    url: _url,
  });
};

export const postChatById = (esb_id, message) => {
  let _url = '/signboard/' + esb_id + '/chat';
  return request({
    method: 'post',
    url: _url,
    data: {
      message: message,
      messageType: 0,
    },
  });
};

export const register = (email, signboardId) => {
  try {
    const res = axios.post(
      client_url + 'register/email',
      {
        email: email,
        signboardId: signboardId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const getImagesById = (esb_id) => {
  let _url = '/signboard/' + esb_id + '/image';
  return request({
    method: 'get',
    url: _url,
  });
};

export const getFaqsById = (esb_id) => {
  let _url = '/signboard/' + esb_id + '/faq';
  return request({
    method: 'get',
    url: _url,
  });
};

export const getUser = () => {
  let _url = '/user';
  return request({
    method: 'get',
    url: _url,
  });
};

export const postProfile = (profile) => {
  let _url = '/user/profile';
  return request({
    method: 'post',
    url: _url,
    data: { profile: profile },
  });
};

export const postProfileImage = (formData) => {
  let _url = '/user/profile/image';
  return request({
    method: 'post',
    url: _url,
    data: formData,
  });
};

export const postUserName = (firstName, lastName) => {
  let _url = '/user/name?firstname=' + firstName + '&lastname=' + lastName;
  return request({
    method: 'post',
    url: _url,
  });
};

export const postUserPhone = (phone) => {
  let _url = '/user/phone?phone=' + phone;
  return request({
    method: 'post',
    url: _url,
  });
};

export const postFav = (id) => {
  let _url = '/signboard/' + id + '/fav';
  return request({
    method: 'post',
    url: _url,
  });
};

export const deleteFav = (id) => {
  let _url = '/signboard/' + id + '/fav';
  return request({
    method: 'delete',
    url: _url,
  });
};

export const postUserPassword = (oldPassword, newPassword) => {
  let _url = '/user/password';
  return request({
    method: 'post',
    url: _url,
    data: {
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
  });
};

export const postResponse = (content, postUrl) => {
  let _url = postUrl;
  return request({
    method: 'post',
    url: _url,
    data: [content],
  });
};

export const getOldChatByEsbId = (esb_id, sort, page) => {
  let _url = '/signboard/' + esb_id + '/chat/old?sortAsc=' + sort;
  if (page) {
    _url = _url + '&page=' + page;
  }
  return request({
    method: 'get',
    url: _url,
  });
};

export const getNewChatByEsbId = (esb_id, sort) => {
  let _url = '/signboard/' + esb_id + '/chat/new?sortAsc=' + sort;
  return request({
    method: 'get',
    url: _url,
  });
};

export const getSignboardUnread = (esb_id) => {
  let _url = '/signboard/' + esb_id + '/chat/unread-count';
  return request({
    method: 'get',
    url: _url,
  });
};

export const updateChatRead = (esb_id, chat_id) => {
  let _url = '/signboard/' + esb_id + '/chat/' + chat_id + '/read';
  return request({
    method: 'put',
    url: _url,
    data: {},
  });
};

export const getPropertyLinkById = (esb_id) => {
  let _url = 'signboard/' + esb_id + '/link';
  return request({
    method: 'get',
    url: _url,
  });
};

export const postUserGender = (gender) => {
  let _url = '/user/gender?gender=' + gender;
  return request({
    method: 'post',
    url: _url,
  });
};

export const postUserAge = (age) => {
  let _url = '/user/age?age=' + age;
  return request({
    method: 'post',
    url: _url,
  });
};

export const getChatByEsbId = (esb_id, sort) => {
  let _url = '/signboard/' + esb_id + '/chat?sortAsc=' + sort;
  return request({
    method: 'get',
    url: _url,
  });
};

export const getSelfChat = () => {
  let signboard = JSON.parse(localStorage.getItem('signboard'));
  return new Promise((res) =>
    res({
      data: {
        chatMessages: [
          {
            id: 0, //序号
            name: signboard.agent.firstName + ' ' + signboard.agent.lastName, //姓名
            photo: signboard.agent.photoUrl && signboard.agent.photoUrl.trim() !== '' ? signboard.agent.photoUrl : '/user.png', //头像
            position: 'left', //位置,buyer端agent在左
            message: 'Hello, welcome to eSignboard and thanks for scan the QR code. I am the principle agent of the property: ' + signboard.address + ', ' + signboard.suburb + ' ' + signboard.postcode + ', ' + signboard.state + '.', //消息
            messageType: 'TEXT', //消息类型  TEXT,  SINGLE_CHOICE, EMAIL
            timestamp: new Date().toISOString().substring(0, 19), //时间
          },
          {
            id: 1, //序号
            name: signboard.agent.firstName + ' ' + signboard.agent.lastName, //姓名
            photo: signboard.agent.photoUrl && signboard.agent.photoUrl.trim() !== '' ? signboard.agent.photoUrl : '/user.png', //头像
            position: 'left', //位置,buyer端agent在左
            message: 'This is a ' + signboard.bed + ' bedrooms, ' + signboard.bath + ' bathrooms, and ' + signboard.carpark + ' car park ' + signboard.propertyType + '.',
            action: 'EMAIL', //消息类型 =messageType TEXT,  SINGLE_CHOICE, EMAIL
            timestamp: new Date().toISOString().substring(0, 19), //时间
          },
        ],
      },
    })
  );
};

export const getAgentEsbCountByAgentId = (agent_id) => {
  let _url = '/signboard/count?agentId=' + agent_id;
  return request({
    method: 'get',
    url: _url,
  });
};

export const postShareStatsByEsbId = (esb_id, share_type) => {
  let _url = '/signboard/' + esb_id + '/stats/' + share_type;
  return request({
    method: 'post',
    url: _url,
  });
};

export const getSuggest = (terms) => {
  let url = domain_url + 'v1/properties/_suggest?terms=' + terms + '&channel=all&pageSize=5';
  return domainApiGet(url);
};

export const getProperties = (id) => {
  let url = domain_url + 'v1/properties/' + id;
  return domainApiGet(url);
};

export const addSignboard = (profile) => {
  let _url = '/signboard/add';
  return request({
    method: 'post',
    url: _url,
    data: profile,
  });
};

export const getPublicSignboardById = (id) => {
  let _url = '/signboard/public/' + id;
  return request({
    method: 'get',
    url: _url,
  });
};

export const searchSignboard = (domainId, agentContact, agentEmail) => {
  let _url = `/signboard/public/search?domainPropertyId=${domainId}&agentContactNumber=${agentContact}&agentEmail=${agentEmail}`;
  return request({
    method: 'get',
    url: _url,
  });
};

export const searchFeaturedProperties = () => {
  // TODO: get the featured properties by (page, rowPerpage, urlSlug, status etc)
};

export const getFeaturedPropertyByPID = (pid) => {
  let _url = `/signboard/featured/${pid}`;
  return request({
    method: 'get',
    url: _url,
  });
};

export const agentHubConnection = () => {
  return new HubConnectionBuilder()
    .withUrl(agent_url + 'hubs/message')
    .withAutomaticReconnect()
    .build();
};

export const clientHubConnection = () => {
  return new HubConnectionBuilder()
    .withUrl(client_url + 'hubs/message')
    .withAutomaticReconnect()
    .build();
};

export const broadcastMessage = async (message) => {
  let _url = 'signboard/messages/broadcast';
  return request({
    method: 'post',
    url: _url,
    data: {
      message: message,
      messageType: 1,
    },
  });
};

export const agentHubPostMessage = async (esb_id, buyer_id, message) => {
  let _url = `signboard/${esb_id}/buyer/${buyer_id}/messages`;
  return fetch(agent_url + _url, {
    method: 'POST',
    body: JSON.stringify({ message: message, messageType: 0 }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const clientHubPostMessage = async (esb_id, buyer_id, message) => {
  let _url = `signboard/${esb_id}/buyer/${buyer_id}/messages`;
  return request({
    method: 'post',
    url: _url,
    data: {
      message: message,
      messageType: 0,
    },
  });
};
