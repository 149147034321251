import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import PWAPanels from './Components/PWA/PWAPanels';
import Settings from './Layout/Menu/Settings';
import Share from './Layout/Menu/ShareSheet';
import store from './Services/Store/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReactGA from 'react-ga4';
import { CONFIG } from './constants';

ReactGA.initialize(CONFIG.GA_UAT_CLIENT_MEASUREMENT_ID);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <Settings />
        <Share />
        <PWAPanels />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorkerRegistration.register();
