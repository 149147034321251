import './assets/scss/bootstrap.scss';
import './assets/scss/style.scss';
import './assets/fonts/css/fontawesome-all.min.css';
import './assets/fonts/cssv6/all.min.css';
import './i18n';

import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, } from 'react-router-dom';
import Preloader from './Layout/Preloader/Preloader';
import { t } from 'i18next';
import ReactGA from 'react-ga4';
import { useClearCache } from '@herowcode/react-clear-cache';
import CardStyle, { CardCenter } from './Components/Content/Card';
import ESignboardIdlePrompt from './Pages/ESignboard/ESignboardIdlePrompt';
import { logout } from './Services/esignboard.services';
import esignboard_full_logo_icon from './assets/images/esignboard/logo/IconOnly_Transparent_NoBuffer.png';
import Btn from './Components/Btn/Btn';
import { useIdleTimer } from 'react-idle-timer';

const ESignboardError401 = React.lazy(() => import('./Pages/ESignboard/ESignboardError401'));
const ESignboardError = React.lazy(() => import('./Pages/ESignboard/ESignboardError'));
const ESignboardLanding = React.lazy(() => import('./Pages/ESignboard/ESignboardLanding'));
const ESignboardEnter = React.lazy(() => import('./Pages/ESignboard/ESignboardEnter'));
const Home = React.lazy(() => import('./Pages/Home'));
const FeatureRoutes = React.lazy(() => import('./Routes/FeatureRoutes'));
const PageRoutes = React.lazy(() => import('./Routes/PageRoutes'));
const Search = React.lazy(() => import('./Pages/Search'));
const ESignboardRoutes = React.lazy(() => import('./Routes/ESignboardRoutes'));
const MeRoutes = React.lazy(() => import('./Routes/MeRoutes'));
const ESignboardPublicPage = React.lazy(() => import('./Pages/ESignboard/ESignboardPublicPage'));
const ESignboardLeading = React.lazy(() => import('./Pages/ESignboard/ESignboardLeading'));

function App() {
  const { latestVersion, isLatestVersion, emptyCacheStorage } = useClearCache();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);

  const handleOnIdle = () => {
    console.log('user is idle');
    console.log('last active', getLastActiveTime());
    if (localStorage.getItem('session'))
      doLogout();
  }

  const handleOnActive = event => {
    console.log('user is active', event);
    console.log('time remaining', getRemainingTime());
  }

  const handleOnAction = event => {
    // console.log('user did something', event);
  }

  const handleOnPrompt = events => {
    if (localStorage.getItem('session'))
      setShowPrompt(true);
  }

  const { reset, getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 20, // 20mins
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    onPrompt: handleOnPrompt,
    promptTimeout: 1000 * 20, // 20sec
    debounce: 500
  });

  const doLogout = () => {
    logout()
      .then((res) => {
        localStorage.removeItem('session');
        localStorage.removeItem('userId');
        localStorage.removeItem('user');
        localStorage.removeItem('signboard');
        localStorage.removeItem('signboard.images');
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    if (localStorage.getItem('lng') == null)
      localStorage.setItem('lng', 'en');
  }, []);

  return (
    <Suspense fallback={<Preloader />}>
      {!isLatestVersion ? (
        <CardStyle height="cover" className="m-0 rounded-0">
          <CardCenter className="px-5 text-center">
            <div className=" mt-5 pt-3">
              <img className="img-fluid" alt="eSignboard Logo" src={esignboard_full_logo_icon} style={{ 'maxHight': '100px', 'maxWidth': '100px' }} />
            </div>
            <h1 className="color-dark-dark font-22 font-600">eSignboard</h1>
            <h1 className="font-14 font-300 mb-2 mt-0 line-height-s">{t('new.version')}</h1>
            <Btn size="xl" bg="transparent" color="orange-dark"
              onClick={(e) => {
                e.preventDefault();
                emptyCacheStorage();
              }}>
              <span className="font-18 font-400">[ {t('update.version')} ]</span>
            </Btn>
          </CardCenter>
        </CardStyle>
      ) : (
        <Routes>
          <Route path="/" element={<ESignboardLanding />} />
          <Route path="/leading" element={<ESignboardLeading />} />
          <Route path="/home" element={<Home />} />
          <Route path="/features/*" element={<FeatureRoutes />} />
          <Route path="/pages/*" element={<PageRoutes />} />
          <Route path="/search/*" element={<Search />} />
          <Route path="/esignboard/*" element={<ESignboardRoutes />} />
          <Route path="/signboard/scan/:id" element={<ESignboardEnter />} />
          <Route path="/property/:esbid" element={<ESignboardPublicPage />} />
          <Route path="/me/*" element={<MeRoutes />} />
          <Route path="/error/401" element={<ESignboardError401 />} />
          <Route path="*" element={<ESignboardError />} />
        </Routes>
      )}
      <ESignboardIdlePrompt showPrompt={showPrompt} setShowPrompt={setShowPrompt} reset={reset} placement="center" />
    </Suspense>
  );
}

export default App;
